import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'; 
import './Nav1.css';
import Logo1 from '../images/prop1logo.png';
import Background from '../images/Background.png';

const Nav1 = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className='main-container-1'>
      <nav className='nav-container-1'>
        <div className='image-container-1'>
          <img src={Logo1} alt='Logo' />
        </div>
        <div className='burger-menu-1' onClick={toggleMenu}>
          <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} size="2x" color="white" />
        </div>
        <div className={`menu-1 ${isMenuOpen ? 'open-1' : ''}`}>
          <ul className='list-container-1'>
            <li>
              <NavLink exact to='/' activeClassName="active" onClick={toggleMenu}>Home</NavLink>
            </li>
            <li>
              <NavLink to='/about' activeClassName="active" onClick={toggleMenu}>About Us</NavLink>
            </li>
            <li>
              <NavLink to='/contact' activeClassName="active" onClick={toggleMenu}>Contact Us</NavLink>
            </li>
          </ul>
        </div>
      </nav>

      <div className='image-container-2'>
        <img src={Background} alt='' />
        <div className="about-text-2">
          <p className='p1-1'>Welcome to</p>
          <h1 className='p2-1'>Prop Assist</h1>
          <p className='p3-1'>Crafting Your Real Estate Vision</p>
          <p className='p4-1'>Your 
            <span className='p4bg-1'> search starts now..</span>
          </p>
          <p className='p5-1'>
            <button className='b1-1'>
              <NavLink className='linkb1-1' to='/contactcomp'>Connect with us</NavLink>
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Nav1;
