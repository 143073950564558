import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for routing
import './Footer.css';
import logo from '../images/prop2logo.png';
import facebook from '../images/facebook-prop.png';
import insta from '../images/insta-prop.png';
import linkd from '../images/linked-prop.png';

const Footer = () => {
  return (
    <div className='main-footer-cont'>
      <div className='main-foot'>
        <div className='foot-left'>
          <img src={logo} alt='Logo'></img>
          <div className='foot-left-1'>
            <p>3rd floor, 302, West Avenue,Opp. PMRDA Office, Bremen Square, Aundh, Pune-411067 Maharashtra, India</p>
            <p>Registered under RERA #A52100047490</p>
            <div className='foot-icon'>
              <a href="https://www.facebook.com/propassistpune">
                <img src={facebook} alt="Facebook"></img>
              </a>
              <a href="https://www.instagram.com/propassist_2023/">
                <img src={insta} alt="Instagram"></img>
              </a>
              <a href="https://www.linkedin.com/company/propassist24by7/">
                <img src={linkd} alt="LinkedIn"></img>
              </a>
            </div>
          </div> 
        </div>
        
        <div className='foot-right'>
          <div className='foot-1'>
            <h3>Top Cities</h3>
            <p>Pune</p>
            <p>Mumbai</p>
            <p>Chennai</p>
          </div>
          
          <div className='foot-1'>
            <h3>Explorer</h3>
            <p><Link to="/career">Career</Link></p>
            <p><Link to="/terms">Terms of Use</Link></p>
            <p><Link to="/blogs">Blogs</Link></p>
          </div>
          
          <div className='foot-1'>
            <h3>Know more</h3>
            <p><Link to="/about">About Us</Link></p> {/* Link to About Us page */}
            <p><Link to="/contact">Contact Us</Link></p> {/* Link to Contact Us page */}
            <p><Link to="/privacy-policy">Privacy Policy</Link></p> {/* Link to Privacy Policy */}
          </div>
        </div>
      </div>
      
      <div className='foot-bottom'>
        <p>
          <a className="Footer_copyright" href="https://corticawebsolutions.com/">
            Copyright © 2024 Bluerock Enterprises LLP | Powered by CORTICA 
          </a>
        </p>
      </div>
    </div>
  );
}

export default Footer;
