// WhatsAppButton.jsx

import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import logo from '../images/prop1logo.png'

const WhatsappButton = () => {
  return (
    <div
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 1000, // Ensures it’s above content but not overlaying everything
        width: '60px', // Constrains the width of the button’s click area
        height: '60px',
      }}
    >
     <FloatingWhatsApp
  phoneNumber="+919860099550"
  accountName="Prop Assist"
  chatMessage="Hello,
 Thanks for sharing your valuable time with us. We would like to discuss on a couple of really great property investment options across Pune, Mumbai & Chennai.
 Let's us know a good time to connect with you over a call?"
  avatar={logo}
  allowClickAway
  notification
  notificationSound
/>
    </div>
  );
};

export default WhatsappButton;
