import React from 'react'
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './Careercomp4.css'


const Careercomp4 = () => {
  return (
    <div className='careercomp4'>
    <h1>Current Positions</h1>
    <div className='carrercomp4-card'>
        <h3>Sales Manager</h3>
        <div className='careercomp4-exp'>
            <p><i class="fa-solid fa-location-dot"></i>Pune</p>
            <p><i class="fa-regular fa-user"></i>Full Time</p>
            <p><i class="fa-solid fa-list"></i>2-5 Experince</p>
        </div>
        <li>Manage a sales team and provide leadership, training and coaching</li>
        <li>Develop a sales strategy to achieve organizational sales goals and revenues</li>
        <li>Set individual sales targets with sales team</li>
        <li>Work on sales scripts with agents</li>
        <li>Track, collate and interpret sales figures and reporting</li>
        <li>Ensure members of the sales team have the necessary resources to perform properly</li>
        <li>Plan and direct sales team training</li>
        <li>Control expenses and monitor budgets</li>
        <button className='careercomp4-btn'><Link to='/contact'>Apply Now</Link></button>
    </div>
    <div className='carrercomp4-card'>
        <h3>Sales Manager</h3>
        <div className='careercomp4-exp'>
            <p><i class="fa-solid fa-location-dot"></i>Pune</p>
            <p><i class="fa-regular fa-user"></i>Full Time</p>
            <p><i class="fa-solid fa-list"></i>0-2 Experince</p>
        </div>
        <li>Calling prospective customers & explaining projects</li>
        <li>Carrying out site visits</li>
        <li>Identify prospective clients</li>
        <li>Follow up with qualified leads to close the deals</li>
        <li>Price negotiations for final deals</li>
        <li>Follow up with qualified leads to close the deals</li>
        <button className='careercomp4-btn'><Link to='/contact'>Apply Now</Link></button>
    </div>
    </div>
  )
}

export default Careercomp4