import React from 'react'
import Viewallcomp1 from './Viewallcomp1'
import image1 from '../images/1view.jpg'
import image2 from '../images/2view.jpg'
import image3 from '../images/view3.jpg'
const Apartment = () => {
  return (
    <div>
        <Viewallcomp1/>
         <div className='viewallcomp2-cont'>
            <div className='viewallcomp2-card-cont'>
                <div className='viewallcomp2-card'>
                    <div className='viewallcomp2-image-cont'>
                        <img src={image1}alt=''></img>
                        <div className='viewallcomp2-card-info'>
                            <p>Apartment</p>
                            <h3>Eon Waterfront by Panchshil</h3>
                            <p>Kharadi</p>
                            <div className='viewallcomp2-card-subheading'>
                                <p><b>Kharadi -</b>4.5 / Penthouses

</p>
                                <p><b>SqFt -</b>4100</p>
                            </div>
                            <div>
                                <p className='viewallcomp2-card-subheading2' >Price On Request</p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
           
            <div className='viewallcomp2-card-cont'>
                <div className='viewallcomp2-card'>
                    <div className='viewallcomp2-image-cont'>
                        <img src={image2}alt=''></img>
                        <div className='viewallcomp2-card-info'>
                            <p>Apartment</p>
                            <h3>Eon Waterfront by Panchshil</h3>
                            <p>Kharadi</p>
                            <div className='viewallcomp2-card-subheading'>
                                <p><b>Kharadi -</b>4.5 / Penthouses

</p>
                                <p><b>SqFt -</b>4100</p>
                            </div>
                            <div>
                                <p className='viewallcomp2-card-subheading2' >Price On Request</p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
           
           
            <div className='viewallcomp2-card-cont'>
                <div className='viewallcomp2-card'>
                    <div className='viewallcomp2-image-cont'>
                        <img src={image3}alt=''></img>
                        <div className='viewallcomp2-card-info'>
                            <p>Apartment</p>
                            <h3>Eon Waterfront by Panchshil</h3>
                            <p>Kharadi</p>
                            <div className='viewallcomp2-card-subheading'>
                                <p><b>Kharadi -</b>4.5 / Penthouses

</p>
                                <p><b>SqFt -</b>4100</p>
                            </div>
                            <div>
                                <p className='viewallcomp2-card-subheading2' >Price On Request</p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
    </div>
    </div>
  )

}

export default Apartment