import React from 'react';
import './Homecomponent5.css'

const Homecomponent5 = () => {
  return (
    <div className='Homecomponent5-heading'>
    <h2>Client Testmonials</h2>
    </div>
  );
};

export default Homecomponent5;
