import React from 'react'
import Termscomp from '../components/Termscomp'

const Terms = () => {
  return (
    <div>
        <Termscomp/>
    </div>
  )
}

export default Terms