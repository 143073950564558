import React from 'react'
import './Termscomp.css'
const Termscomp = () => {
  return (
    <div className='Terms-cont'> 
    <h3><b>Terms of Use</b></h3>
    <p>A. INTRODUCTION</p>
    <p>Our customers’ privacy is extremely important to us at Bluerock Enterprises LLP. When you are visiting us as a Customer / Subscriber / Buyer / Visitor / Users at https://www.propassist.in/, henceforth referred to as Prop Assist (Brand name for our real estate consulting vertical) . You can depend on us to act responsibly and maintain confidentiality of the information that you choose to provide us with. The information that you provide will help us to better understand your needs and enable us to serve you better.</p>
    <p>B. INFORMATION COLLECTED FROM YOU</p> 
    <p>To be able to obtain information about our products and services you are required to provide us with a valid email ID, and your correct contact details. This enables us to provide you the relevant information whether by sending a representative or otherwise, without the need of asking you for your address details repeatedly</p> 
    <p>We may ask you for further information from time to time in order to cater to your needs. The information that you provide will not be shared with anybody without your prior explicit consent. This information will help us in providing you with even better service levels than you have come to expect from Prop Assist. We may track usage through cookies or session variables. A cookie is a small data file written to a user’s hard drive, a session variable is like a temporary cookie and it is destroyed when you close your browser. Cookies may be used to, among other things, allow automated log-in and may contain information about preferences or Internet use. Advertisers and affiliate partners on our site may also send cookies to your browser.</p> 
    <p>We may also collect information about your preferences. For example, what type of property you may be interested in. We may possibly keep on record information relating to customer service requests as well as other correspondence, such as emails or letters, sent to us. Your responses to online surveys, questionnaires etc. may also be kept on record for us to better understand your needs and requirements.</p> 
    <p>C. STORAGE AND MAINTENANCE OF INFORMATION COLLECTED</p> 
    <p>Although https://www.propassist.in/ may store or track information about visitor, Bluerock Enterprises LLP shall not be obligated to do so and may delete any information and records, in whole or in part, solely at its discretion.</p> 
    <p>D. USAGE OF THE INFORMATION COLLECTED</p> 
    <p>The information collected from our users / visitors is used mainly to enhance the end-user experience. The email and other contact information provided may be used to send promotional offers, newsletter and contact users in case of any emergency or booking conflict.</p> 
    <p>No information regarding personal details of its users and members is rented or sold to any third parties whatsoever. However, statistical analysis details may be shared with our affiliates, partners or third-party associates to gain a better understanding of user / visitor behavior on the https://www.propassist.in/. The information shared would be aggregate form and no personal information would be shared with any party whatsoever.</p> 
    <p>Prop Assist will share personal information only in the following cases: Bluerock Enterprises LLP strives to protect the personally identifiable information of the users and may disclose the personally identifiable information only when:</p>  
    <p>Authorized explicitly by the user/visitor</p>  
    <p>Required by the governing laws of the land</p>  
    <p>Required for providing / fulfilling a requested service</p>  
    <p>Please note that Bluerock Enterprises LLP may sell, share or transfer personally identifiable information about its customers with any successor in interest (e.g. if the Company is sold to a third party). Should Bluerock Enterprises LLP be legally required to disclose any personal information about a user / visitor in case it is demanded by law enforcement authorities in connection with any investigation, or civil or administrative authorities in connection with a case of civil or administrative investigation, in such cases this information will be shared with the concerned authorities.</p>  
    <p>E. SITE SECURITY</p>  
    <p>Bluerock Enterprises LLP has implemented adequate security measures to protect against any misuse, theft or alteration of information stored on the https://www.propassist.in/ web site. The site is verified by us as virus-free however, the risk of hacking remains. In case of any computer or hack attacks on the site, Bluerock Enterprises LLP cannot be held liable for loss, theft or corruption of data maintained on the site.</p>  
    <p>F. OPTIONS FOR PROMOTIONAL INFORMATION AND COMMUNICATION</p>  
    <p>Users reserve the right to opt-out of receiving any promotional or other communications from Bluerock Enterprises LLP or any of its affiliates. Should you choose to opt out please do so by visiting the site, signing in and then unchecking the receive newsletter option.</p>  
    <p>G. GENERAL LIMITATION OF LIABILITY</p>  
    <p>Please be advised that Bluerock Enterprises LLP tries its best to collect data from authentic sources. However, Bluerock Enterprises LLP cannot be held liable for any loss, monetary or otherwise, resulting from the usage and display of this data.</p>    
    </div>
  )
}

export default Termscomp