import React from 'react'

import Viewallcomp2 from '../components/Viewallcomp2'
const Viewall = () => {
  return (
    <div>
      
        <Viewallcomp2/>
    </div>
  )
}

export default Viewall