import React from 'react'
import Homecomp1 from '../components/Homecomp1'
 import Homecomp2 from '../components/Homecomp2'
import Homecomp3 from '../components/Homecomp3'
import Homecomponent4 from '../components/Homecomponent4'
 import Homecomponent5 from '../components/Homecomponent5'
 import Homecomponent6 from '../components/Homecomponent6'
import Homecomponent7 from '../components/Homecomponent7'
import Homecomponent8 from '../components/Homecomponent8'

const Home = () => {
  return (
    <div>
 <Homecomp1/>
   <Homecomp2/>
   <Homecomp3/>
   <Homecomponent4/>
 <Homecomponent5/>
  <Homecomponent6/>
 <Homecomponent7/>
    <Homecomponent8/>   
    </div>
  )
}

export default Home