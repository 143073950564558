// Contactcompo.jsx
import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import emailjs from 'emailjs-com';
import './Contactcompo.css';

const Contactcompo = () => {
  const cities = ["Pune", "Mumbai", "Chennai"];
  const propertyTypes = ["Apartment", "House", "Villa", "Land", "Commercial"];

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    location: '',
    propertyType: '',
    inquiry: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneChange = (phone) => {
    setFormData({ ...formData, phone });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // EmailJS send method
    emailjs.send('service_0ssa59j', 'template_5rqydxn', {
      from_name: formData.fullName,
      from_email: formData.email,
      from_phone: formData.phone,
      location: formData.location,
      property_type: formData.propertyType,
      inquiry: formData.inquiry
    }, 'uBc9T3yxgNpwsno_P')  // Replace with your user ID
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      alert('Your inquiry has been sent successfully!');
      // Reset the form
      setFormData({
        fullName: '',
        email: '',
        phone: '',
        location: '',
        propertyType: '',
        inquiry: ''
      });
    }, (err) => {
      console.error('FAILED...', err);
      alert('Failed to send your inquiry, please try again later.');
    });
  };

  return (
    <div className="contact2-form-page">
      <div className="contact2-form-main">
        <div className="contact2-form-heading">
          <h2>Interested? Enquire Now</h2>
        </div>
        
        <form onSubmit={handleSubmit} className="contact2-inquiry-form">
          <div className="contact2-form-group">
            <input
              type="text"
              placeholder="Enter your name"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              required
            />
          </div>

          <div className="contact2-form-group contact2-form-group-inline">
            <input
              type="email"
              placeholder="Enter your email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <PhoneInput
              country={'us'}
              value={formData.phone}
              onChange={handlePhoneChange}
              placeholder="Phone number"
              required
              className="contact2-phone-input"
            />
          </div>

          <div className="contact2-form-group">
            <select
              name="location"
              value={formData.location}
              onChange={handleChange}
              required
            >
              <option value="">Select Location</option>
              {cities.map((city, index) => (
                <option key={index} value={city}>{city}</option>
              ))}
            </select>
          </div>

          <div className="contact2-form-group">
            <select
              name="propertyType"
              value={formData.propertyType}
              onChange={handleChange}
              required
            >
              <option value="">Select Property Type</option>
              {propertyTypes.map((type, index) => (
                <option key={index} value={type}>{type}</option>
              ))}
            </select>
          </div>

          <div className="contact2-form-group">
            <textarea
              placeholder="Your message"
              name="inquiry"
              value={formData.inquiry}
              onChange={handleChange}
              rows="2"
              required
            />
          </div>

          <div className="contact2-button-container">
            <button type="submit" className="contact2-submit-btn">
              Connect with us
            </button>
          </div>
        </form>
      </div>

      <div className="contact2-image-container">
        <img src="https://propassist.in/static/media/Contact-Us-animation.412f0e9a86d23826eb0a.gif" alt="Contact Us Animation" />
      </div>
    </div>
  );
};

export default Contactcompo;
