import React from 'react';
import Abhi from '../images/Abhi.jpeg'
import Vinit from '../images/vinit_enhanced.jpeg'
import './About5component.css';

const About5component = () => {
  return (
    <div className='about-5-container'>
      <h1>Our Leadership</h1>
         <div className='about-5-main-container'>
           <div className='about-5-leader-1'>
           <div className='about-5-img1-cont'>
          <img src={Abhi} alt=''></img>
           </div>
           <div className='about-5-info-1'>
           <h2>Mr. Abhijit Shiralkar</h2>
           <h4>Director, Co-Founder</h4>
           <p>In his capacity as a co-founder of Prop Assist,Mr.Abhijit brings a wealth of experience from the real estate sector,showcasing an impressive history of more than seven years of collaboration with esteemed organizations like Lodha Group,Godrej Properties,and Adani Realty.Within Prop Assist,he assumes a multifaceted role that encompasses overseeing sales,cultivating corporate relationships,and effectively managing the brand's digital footprint.</p>
           </div>
           </div>
           <div className='about-5-leader-2'>
           <div className='about-5-img2-cont'>
            <img src={Vinit} alt=''></img>
           </div>
           <div className='about-5-info-2'>
           <h2>Mr. Vineeth Gopalakrishnan</h2>
           <h4>Director, Co-Founder </h4>
           <p>Vineeth, a seasoned professional, brings over a decade of expertise in sales, marketing, and banking operations. Having worked with renowned brands like ICICI Bank, Anarock Group, Piramal Realty, and Panchshil Realty, he founded Prop Assist and looks after the strategy & sales. Beyond his career, he finds joy in acting, drama, and is an avid table tennis player.  </p>
           </div>
           </div>
         </div>
    </div>
  );
};

export default About5component;
