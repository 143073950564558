import React from 'react';
import './Contactcomp.css';

const Contactcomp = () => {
  return (
    <div className='contact-cont'>
      <div className='contact-left'>
        {/* Address Section */}
        <div className='contact-left-1'>
          <div className='logo'>
            <i className="fa-solid fa-location-dot"></i>
          </div>
          <div className='con-info'>
            <div className='contact-left-info'>Address</div>
            <div className="contact-address">
              3rd floor, 302, West Avenue, Opp. PMRDA Office, Bremen Square, Aundh, Pune-411067, Maharashtra, India
            </div>
          </div>
        </div>

        {/* Phone Section */}
        <div className='contact-left-1'>
          <div className='logo'>
            <i className="fa-solid fa-phone"></i>
          </div>
          <div className='con-info'>
            <div className='contact-left-info'>Phone No</div>
            <div className="contact-address">+91 9860099550</div>
          </div>
        </div>

        {/* Email Section */}
        <div className='contact-left-1'>
          <div className='logo'>
            <i className="fa-solid fa-envelope"></i>
          </div>
          <div className='con-info'>
            <div className='contact-left-info'>Email Us</div>
            <div className="contact-address">sales@propassist.in</div>
          </div>
        </div>
      </div>

      {/* Google Map Section */}
      <div className='contact-right'>
        <iframe className='iframe-cont'
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d332.1660981448898!2d73.8134189286441!3d18.56370905923208!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf3cf6fd485f%3A0x3807395fe0ddce1e!2sWest%20Avenue!5e0!3m2!1sen!2sin!4v1729146439633!5m2!1sen!2sin"
          allowFullScreen="" 
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Map"
        ></iframe>
      </div>
    </div>
  );
}

export default Contactcomp;
