import React from 'react'
import './Viewallcomp2.css'
import image1 from '../images/1view.jpg'
import image2 from '../images/2view.jpg'
import image3 from '../images/view3.jpg'
import image4 from '../images/view4.jpg'
import image5 from '../images/view5.jpeg'
import image6 from '../images/view6.jpg'
import image7 from '../images/view7.png'
import image8 from '../images/view8.jpg'
import image9 from '../images/view9.jpg'
import image10 from '../images/view10.jpg'
import Viewallcomp1 from './Viewallcomp1'
import { Link } from 'react-router-dom';
const Viewallcomp2 = () => {
  return (
    <div>
         <Viewallcomp1/>
        <div className='viewallcomp2-cont'>
            <div className='viewallcomp2-card-cont'>
                <div className='viewallcomp2-card'>
                    <div className='viewallcomp2-image-cont'>
                        <img src={image1}alt=''></img>
                        <div className='viewallcomp2-card-info'>
                            <p>Apartment</p>
                            <h3>Eon Waterfront by Panchshil</h3>
                            <p> <i className="fa-solid fa-map-pin"></i> Kharadi</p>
                            <div className='viewallcomp2-card-subheading'>
                                <p><b>Kharadi -</b>4.5 / Penthouses

</p>
                                <p><b>SqFt -</b>4100</p>
                            </div>
                            <div>
                            <p>
                                                <Link to="/contactcomp" className='price-link' >Price On Request</Link>
                                            </p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
           
            <div className='viewallcomp2-card-cont'>
                <div className='viewallcomp2-card'>
                    <div className='viewallcomp2-image-cont'>
                        <img src={image2}alt=''></img>
                        <div className='viewallcomp2-card-info'>
                            <p>Apartment</p>
                            <h3>Lodha Giardino</h3>
                            <p>  <i className="fa-solid fa-map-pin"></i> Kharadi Nxi</p>
                            <div className='viewallcomp2-card-subheading'>
                                <p><b>Beds -</b>4.5 / Penthouses

</p>
                                <p><b>SqFt -</b>4100</p>
                            </div>
                            <div>
                            <p>
                                                <Link to="/contactcomp" className="price-link">Price On Request</Link>
                                            </p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
           
           
            <div className='viewallcomp2-card-cont'>
                <div className='viewallcomp2-card'>
                    <div className='viewallcomp2-image-cont'>
                        <img src={image3}alt=''></img>
                        <div className='viewallcomp2-card-info'>
                            <p>Apartment</p>
                            <h3>Kasturi Hill Side</h3>
                           <p> <i className="fa-solid fa-map-pin"></i> Banner</p>
                            <div className='viewallcomp2-card-subheading'>
                                <p><b>Beds - </b>3.5 / 4.5

</p>
                                <p><b>SqFt -</b>1600 - 2000</p>
                            </div>
                            <div>
                            <p>
                                                <Link to="/contactcomp" className="price-link">Price On Request</Link>
                                            </p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
           
           
            <div className='viewallcomp2-card-cont'>
                <div className='viewallcomp2-card'>
                    <div className='viewallcomp2-image-cont'>
                        <img src={image4}alt=''></img>
                        <div className='viewallcomp2-card-info'>
                            <p>Office Space</p>
                            <h3>Kundan Wyng - The Camp Capitol</h3>
                            <p> <i className="fa-solid fa-map-pin"></i> Camp - Collector Office</p>
                            <div className='viewallcomp2-card-subheading'>
                                <p><b>Beds -</b>4.5 / Penthouses

</p>
                                <p><b>SqFt -</b> 1000 - 16000 ++</p>
                            </div>
                            <div>
                            <p>
                                                <Link to="/contactcomp" className="price-link">Price On Request</Link>
                                            </p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
           
           
            <div className='viewallcomp2-card-cont'>
                <div className='viewallcomp2-card'>
                    <div className='viewallcomp2-image-cont'>
                        <img src={image5}alt=''></img>
                        <div className='viewallcomp2-card-info'>
                            <p>Office Spaces</p>
                            <h3>Kohinoor Business Towers</h3>
                           <p> <i className="fa-solid fa-map-pin"></i>  Koregaon Park Nx</p>
                            <div className='viewallcomp2-card-subheading'>
                                <p><b>beds -</b>

</p>
                                <p><b>SqFt -</b>1800 - 16000 ++</p>
                            </div>
                            <div>
                            <p>
                                                <Link to="/contactcomp" className="price-link">Price On Request</Link>
                                            </p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
           
           
            <div className='viewallcomp2-card-cont'>
                <div className='viewallcomp2-card'>
                    <div className='viewallcomp2-image-cont'>
                        <img src={image6}alt=''></img>
                        <div className='viewallcomp2-card-info'>
                            <p>Office Space</p>
                            <h3>One Place by Mittal Group</h3>
                           <p> <i className="fa-solid fa-map-pin"></i> Kharadi</p>
                            <div className='viewallcomp2-card-subheading'>
                                <p><b>Beds -</b>

</p>
                                <p><b>SqFt -</b>1000 - 10000 ++</p>
                            </div>
                            <div>
                            <p>
                                                <Link to="/contactcomp" className="price-link">Price On Request</Link>
                                            </p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
           
           
            <div className='viewallcomp2-card-cont'>
                <div className='viewallcomp2-card'>
                    <div className='viewallcomp2-image-cont'>
                        <img src={image7}alt=''></img>
                        <div className='viewallcomp2-card-info'>
                            <p>Prerelased Assest</p>
                            <h3>Leading Ed-Tech Giant</h3>
                            <p><i className="fa-solid fa-map-pin"></i> Shivaji Nagar</p>
                            <div className='viewallcomp2-card-subheading'>
                                <p><b>Beds -</b>

</p>
                                <p><b>SqFt -</b>25000</p>
                            </div>
                            <div>
                            <p>
                                                <Link to="/contactcomp" className="price-link">Price On Request</Link>
                                            </p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
           
           
            <div className='viewallcomp2-card-cont'>
                <div className='viewallcomp2-card'>
                    <div className='viewallcomp2-image-cont'>
                        <img src={image8}alt=''></img>
                        <div className='viewallcomp2-card-info'>
                            <p>Prerealsed Assest</p>
                            <h3>Leading Retail Brand</h3>
                            <p> <i className="fa-solid fa-map-pin"></i> Baner</p>
                            <div className='viewallcomp2-card-subheading'>
                                <p><b>Beds -</b>

</p>
                                <p><b>SqFt -</b>18000</p>
                            </div>
                            <div>
                            <p>
                                                <Link to="/contactcomp" className="price-link">Price On Request</Link>
                                            </p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
           
           
            <div className='viewallcomp2-card-cont'>
                <div className='viewallcomp2-card'>
                    <div className='viewallcomp2-image-cont'>
                        <img src={image9}alt=''></img>
                        <div className='viewallcomp2-card-info'>
                            <p>Prerealsed Assest</p>
                            <h3>Leading ITES Organization</h3>
                           <p> <i className="fa-solid fa-map-pin"></i> Baner</p>
                            <div className='viewallcomp2-card-subheading'>
                                <p><b>Beds -</b>

</p>
                                <p><b>SqFt -</b> 5300</p>
                            </div>
                            <div>
                            <p>
                                                <Link to="/contactcomp" className="price-link">Price On Request</Link>
                                            </p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
           
            <div className='viewallcomp2-card-cont'>
                <div className='viewallcomp2-card'>
                    <div className='viewallcomp2-image-cont'>
                        <img src={image10}alt=''></img>
                        <div className='viewallcomp2-card-info'>
                            <p>Avaliable on lease</p>
                            <h3>Banks / NBFCs / Insurance</h3>
                           <p>  <i className="fa-solid fa-map-pin"></i> FC Road</p>
                            <div className='viewallcomp2-card-subheading'>
                                <p><b>Beds-</b>

</p>
                                <p><b>SqFt -</b>2500-6000</p>
                            </div>
                            <div>
                            <p>
                                                <Link to="/contactcomp" className="price-link">Price On Request</Link>
                                            </p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
           

        </div>
    </div>
  )
}

export default Viewallcomp2