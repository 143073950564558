import React from 'react'
import Contactcomp from '../components/Contactcomp'
const Contact = () => {
  return (
    <div>
       <Contactcomp/>
       
    </div>
  )
}

export default Contact