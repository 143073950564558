// Homecomponent4.jsx

import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom';

// Import images and CSS
import image1 from '../images/1view.jpg';
import image2 from '../images/2view.jpg';
import image3 from '../images/view3.jpg';
import image4 from '../images/view4.jpg';
import image5 from '../images/view5.jpeg';
import image6 from '../images/view6.jpg';
import image7 from '../images/view7.png';
import image8 from '../images/view8.jpg';
import image9 from '../images/view9.jpg';
import image10 from '../images/view10.jpg';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './Homecomponent4.css';

// Custom next and previous arrow components
const SampleNextArrow = (props) => {
    const { onClick } = props;
    return (
        <button className="react-multiple-carousel__arrow slick-next" onClick={onClick}>
            <span>&gt;</span>
        </button>
    );
}

const SamplePrevArrow = (props) => {
    const { onClick } = props;
    return (
        <button className="react-multiple-carousel__arrow slick-prev" onClick={onClick}>
            <span>&lt;</span>
        </button>
    );
}

function Homecomponent4() {
    const [slidesToShow, setSlidesToShow] = useState(2);

    useEffect(() => {
        const updateSlidesToShow = () => {
            setSlidesToShow(window.innerWidth <= 768 ? 1 : 2);
        };

        updateSlidesToShow();
        window.addEventListener('resize', updateSlidesToShow);
        return () => window.removeEventListener('resize', updateSlidesToShow);
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: slidesToShow,
        slidesToScroll: slidesToShow,
        autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    // Updated images array with specific types for each property
    const images = [
        { img: image1, title: "Eon Waterfront by Panchshil", location: "Kharadi", rating: "4.5 / Penthouses", sqft: "4100", type: "Luxury Apartment" },
        { img: image2, title: "Lodha Giardino", location: "Koregaon Park Nx", rating: "4.5 / Penthouses", sqft: "1600 - 2000", type: "Premium Apartment" },
        { img: image3, title: "Kasturi Hill Side", location: "Baner", rating: "", sqft: "2500 - 6000", type: "Apartment" },
        { img: image4, title: "Kundan Wyng - The Camp Capitol", location: "Camp - Collector Office", rating: "", sqft: "1000 - 16000 ++", type: "Office Space" },
        { img: image5, title: "Kohinoor Business Towers", location: "Koregaon Park Nx", rating: "", sqft: "1000 - 10000 ++", type: "Office Space" },
        { img: image6, title: "One Place by Mittal Group", location: "FC Road", rating: "4.5 / Penthouses", sqft: "25000", type: "Office Space" },
        { img: image7, title: "Leading Ed-Tech Giant", location: "Shivaji Nagar", rating: "", sqft: "18000", type: "Prereleased Assest" },
        { img: image8, title: "Leading Retail Brand", location: "Baner", rating: "", sqft: "5300", type: "Prereleased Assest" },
        { img: image9, title: "Leading ITES Organization", location: "Baner", rating: "", sqft: "2500-6000", type: "Prereleased Assest" },
        { img: image10, title: "Banks / NBFCs / Insurance", location: "FC Road", rating: "", sqft: "7100", type: "Financial Institution" }
    ];

    return (
        <div className="slider-container-1">
            <Slider {...settings}>
                {images.map((property, index) => (
                    <div key={index}>
                        <div className='viewallcomp2-cont-1-1'>
                            <div className='viewallcomp2-card-cont-1-1'>
                                <div className='viewallcomp2-card-1-1'>
                                    <div className='viewallcomp2-image-cont-1-1'>
                                        <img 
                                            src={property.img} 
                                            alt={property.title} 
                                            onError={(e) => { e.target.src = 'path/to/fallback/image.jpg'; }} // Fallback image
                                        />
                                        <div className='viewallcomp2-card-info-1-1'>
                                            <p>{property.type}</p> {/* Display the specific card type here */}
                                            <h3>{property.title}</h3>
                                            <p>
                                                <i className="fa-solid fa-map-pin"></i> {/* Font Awesome Map Pin Icon */}
                                                {property.location}
                                            </p>
                                            <div className='viewallcomp2-card-subheading-1-1'>
                                                <p><b>Beds -</b> {property.rating}</p>
                                                <p><b>SqFt - </b> {property.sqft}</p>
                                            </div>
                                            <p>
                                                <Link to="/contactcomp" className="price-link">Price On Request</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default Homecomponent4;
