import React from 'react'
import './Careercomp3.css'
import image1 from '../images/comp31.png'
import image2 from '../images/careercomp32.jpg'
import image3 from '../images/careercomp34.jpg'
const Careercomp3 = () => {
  return (
    <div className='careercomp3-main'>
        <h1>Why Choose Prop Assist</h1>
    <div className='careercomp3-cont'>
        <div className='carrer3-1'>
        <div className='carrer3-img'>
            <img src={image1} alt=''></img>
        </div>
        <h2 className='career3sub'>Limitless Growth Opportunities</h2>
        </div>

        <div className='carrer3-1'>
            <div className='carrer3-img'>
        <img src={image2} alt=''></img>
        </div>
        <h2 className='career3sub'>Supportive Work Environment</h2>
        </div>
        <div className='carrer3-1'>
            <div className='carrer3-img'>
        <img src={image3} alt=''></img>
        </div>
        <h2 className='career3sub'>Competitive in Compensation</h2>
        </div>

    </div>
    </div>
  )
}

export default Careercomp3