import React from 'react'
import './About4component.css'
const About4component = () => {
  return (
    <div className='about-4-main'>
      <div className='about-4-main-cont'>
      <h1>Why Prop Assist</h1>
      <p>At Prop Assist, we redefine the art of real estate advisory, catering exclusively to seeking bespoke solutions</p>
      <p className='about-4-logo'>
      <i class="fa-solid fa-caret-right"></i>
      Trusted by Thousands </p>
      <p className='about-4-logo'>
      <i class="fa-solid fa-caret-right"></i>
      Wide Range of Properties </p>
      <p className='about-4-logo'>
      <i class="fa-solid fa-caret-right"></i>
      Financing Made Easy </p>
      </div>
    </div>
  )
}

export default About4component