import React from 'react'
import Careercomp1 from '../components/Careercomp1'
 import Careercomp2 from '../components/Careercomp2'
import Careercomp3 from '../components/Careercomp3'
 import Careercomp4 from '../components/Careercomp4'
const Carrer = () => {
  return (
    <div>
        <Careercomp1/>
    <Careercomp2/>
        <Careercomp3/>
       <Careercomp4/>
    </div>
  )
}

export default Carrer