import React from 'react'
import './Homecomponent7.css'
const Homecomponent7 = () => {
  return (
    <div className='Homecomponent-heading'>
     <h1>Our Association</h1>
    </div>
  )
}

export default Homecomponent7