import React from "react";
import Slider from "react-slick";
import starImage from '../images/star5.png'
import './Homecomponent6.css' 
import starImage2 from '../images/star2.png'

function Homecomponent6() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Corrected the property to have a leading lowercase 'a'
    autoplaySpeed: 2000,
  };
  return (
    <div className="slider-container-2">
      <Slider {...settings}>
        <div>
        <div className="Testimonials_container__KNLz4">
            <div className="Testimonials_card__1niE4">
                <h4>Mr. Gohil</h4>
                <img src={starImage} alt="5/5" />
                <p>
                    The Prop Assist team was wonderful to work with. They were attentive and provided valuable insights during the property search. 
                    Our experience with them was positive, and we are happy with the outcome. Thank you, Prop Assist!
                </p>
            </div>
        </div>
        </div>
        <div>
        <div className="Testimonials_container__KNLz4">
            <div className="Testimonials_card__1niE4">
            <h4>Mr &amp; Mrs Mehta</h4>
                <img src={starImage} alt="5/5" />
                <p >
                I am truly grateful to the Prop Assist team for their professionalism and dedication. They went above and beyond to find the perfect property for us, considering all our requirements. The entire experience was smooth and stress-free, leaving us highly satisfied!
                </p>
            </div>
        </div>
        </div>
        <div>
        <div className="Testimonials_container__KNLz4">
            <div className="Testimonials_card__1niE4">
            <h4>M/s Kudale Agro Foods</h4>
                <img src={starImage2} alt="5/5" />
                <p>
                Outstanding service! The Prop Assist team is truly exceptional. They understood our preferences perfectly and showed us some incredible options. They made the buying process effortless, and we are thrilled with our new home. Highly recommended!</p>
            </div>

        </div>
        </div>
        <div>
        <div className="Testimonials_container__KNLz4">
            <div className="Testimonials_card__1niE4">
            <h4>Mr Jat</h4>
                <img src={starImage2} alt="5/5" />
                <p>
                Prop Assist's team demonstrated great expertise and support throughout the buying process. They were knowledgeable and responsive, guiding us effectively. Our overall experience was excellent, with just a minor hiccup that they handled professionally.
                </p>
            </div>
        </div>
        </div>
        <div>
        <div className="Testimonials_container__KNLz4">
            <div className="Testimonials_card__1niE4">
            <h4>Mr Lakhotia</h4>
                <img src={starImage} alt="5/5" />
                <p>
                Working with Prop Assist was a pleasure. Their team was attentive and communicative, addressing our concerns promptly. They helped us find a fantastic property that we are delighted with. Overall, it was a great experience!
                </p>
            </div>
        </div>
        </div>
        <div>
        <div className="Testimonials_container__KNLz4">
            <div className="Testimonials_card__1niE4">
            <h4>Mr Shaikh </h4>
                <img src={starImage2} alt="5/5" />
                <p>
                I had a great experience with Prop Assist. The team was knowledgeable and patiently answered all my questions. They provided valuable support throughout the process, making it a smooth journey. I highly recommend their services!
                </p>
            </div>
        </div>
        </div>
      </Slider>
    </div>
  );
}

export default Homecomponent6;
