import React from 'react'
import choose1 from '../images/choose-1_enhanced.png'
import choose2 from '../images/choose-2_enhanced.png'
import choose3 from '../images/choose-3_enhanced.png'
import './Homecomp2.css'
const Homecomp2 = () => {
  return (
    <div className='choose-service'>
    <h1>Why Choose Us</h1>
    <p>At Prop Assist, we understand that choosing the right real estate agency is a crucial decision that can significantly impact your property buying, selling, or renting experience. Here are some compelling reasons why you should choose us!</p>
    
    <div className='choose-service-1'>
      <div className='choose-box'>
        <div className='choose-image'>
          <img src={choose1} alt='' className='img1' />
        </div>
        <h2>Trusted by thousand</h2>
      </div>
  
      <div className='choose-box'>
        <div className='choose-image'>
          <img src={choose2} alt='' className='img1' />
        </div>
        <h2>Wide Range Of Properties</h2>
      </div>
  
      <div className='choose-box'>
        <div className='choose-image'>
          <img src={choose3} alt='' className='img1' />
        </div>
        <h2>Financing Made Easy</h2>
      </div>
  
  
    </div>
    </div>
  )
}

export default Homecomp2