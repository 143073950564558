import React from 'react'
import banner from '../images/Careercomp2_enhanced.jpg'
import './Careercomp2.css'
const Careercomp2 = () => {
  return (
    <div className='careercomp2-main'>
        <div className='carrercomp2-banner'>
            <img src={banner} alt=''></img>
        </div>
        <div className='carrercomp2-info'>
        <h1>Who We Are ?</h1>
        <p>At Prop Assist, we are not just another real estate company. We are a passionate team dedicated to making a difference in people's lives. With a solid foundation of integrity and professionalism, we strive to exceed client expectations and provide them with the smoothest real estate experience possible. Whether it's buying, selling, or renting, we are there every step of the way.</p>
        </div>
    </div>
  )
}

export default Careercomp2