import React from 'react'
import './About2component.css'
import image1 from '../images/About-flap.png'
import image2 from '../images/About-search.png'
import image3 from '../images/About-king.png'
const About2component = () => {
  return (
    <div className='about-2-main'>
    <div className='about-2-card'>
        <div className='about-2-img'>
            <img src={image1} alt=''></img>
        </div>
        <h2>Our Mission</h2>
        <p>Empower clients through personalized, seamless real estate support.</p>
    </div>
    <div className='about-2-card'>
        <div className='about-2-img'>
            <img src={image2} alt=''></img>
        </div>
        <h2>Our Vision</h2>
        <p>Redefine the industry with innovative technology and exceptional service.</p>
    </div>
    <div className='about-2-card'>
        <div className='about-2-img'>
            <img src={image3} alt=''></img>
        </div>
        <h2>Our Values</h2>
        <p>Integrity, transparency, client-centricity, trust, and lasting relationships.</p>
    </div>
   
    </div>
  )
}

export default About2component