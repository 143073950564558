import React from 'react';
import './Viewallcomp1.css';
import { Link, useLocation } from 'react-router-dom';

const Viewallcomp1 = () => {
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <div className='viewallcomp1-cont'>
      <div className='viewallcomp1-button'>
        <Link to="/Viewallcomp2">
          <button className={`viewallcomp1-btn ${isActive("/Viewallcomp2") ? "active" : ""}`}>All</button>
        </Link>

        <Link to="/apartments">
          <button className={`viewallcomp1-btn ${isActive("/apartments") ? "active" : ""}`}>Apartments/Villas</button>
        </Link>

        <Link to="/office">
          <button className={`viewallcomp1-btn ${isActive("/office") ? "active" : ""}`}>Office Spaces</button>
        </Link>

        <Link to="/preleased">
          <button className={`viewallcomp1-btn ${isActive("/preleased") ? "active" : ""}`}>Preleased Properties</button>
        </Link>

        <Link to="/properties">
          <button className={`viewallcomp1-btn ${isActive("/properties") ? "active" : ""}`}>Properties on Lease</button>
        </Link>
      </div>
    </div>
  );
};

export default Viewallcomp1;
