import React from 'react'
import image10 from '../images/view10.jpg'
import Viewallcomp1 from './Viewallcomp1'
const Properties = () => {
  return (
    <div>
        <Viewallcomp1/>
        <div className='viewallcomp2-cont'>
          <div className='viewallcomp2-card-cont'>
                <div className='viewallcomp2-card'>
                    <div className='viewallcomp2-image-cont'>
                        <img src={image10}alt=''></img>
                        <div className='viewallcomp2-card-info'>
                            <p>Apartment</p>
                            <h3>Eon Waterfront by Panchshil</h3>
                            <p>Kharadi</p>
                            <div className='viewallcomp2-card-subheading'>
                                <p><b>Kharadi -</b>4.5 / Penthouses

</p>
                                <p><b>SqFt -</b>4100</p>
                            </div>
                            <div>
                                <p className='viewallcomp2-card-subheading2' >Price On Request</p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
           

        </div>
    </div>
  )
}

export default Properties