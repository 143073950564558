import React from 'react'
import Privacy from '../components/Privacy'
const PrivacyPolicy = () => {
  return (
    <div>
        <Privacy/>
    </div>
  )
}

export default PrivacyPolicy