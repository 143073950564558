import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Nav1 from './components/Nav1';
import Nav2 from './components/Nav2';
import About from './pages/About';
import Footer from './components/Footer';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import Career from './pages/Carrer';
import Blog from './pages/Blog';
import Blogcomp1 from './components/Blogcomp1';
import Viewall from './pages/Viewall';
import All from './components/Viewallcomp2';
import Apartment from './components/Apartment';
import Office from './components/Office';
import Prerelased from './components/Prerelased';
import Properties from './components/Properties';
import Contactcompo from './components/Contactcompo';
import WhatsAppButton from './components/WhatsappButton';
const Navigation = () => {
  const location = useLocation();
  return location.pathname === '/' ? <Nav1 /> : <Nav2 />;
};

function App() {
  return (
    <Router>
      <div>
        <Navigation />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/career' element={<Career />} />
          <Route path='/blogs' element={<Blog />} />
          <Route path='/blog' element={<Blogcomp1 />} />
          <Route path='/viewall' element={<Viewall />} />
          <Route path='/Viewallcomp2' element={<All />} />
          <Route path='/apartments' element={<Apartment />} />
          <Route path='/office' element={<Office />} />
          <Route path='/preleased' element={<Prerelased />} />
          <Route path='/properties' element={<Properties />} />
          <Route path='/contactcomp' element={<Contactcompo />} />
        </Routes>
        
        <Footer />
        <WhatsAppButton /> {/* Add WhatsAppButton component here */}
       
      </div>

       
    </Router>
  );
}

export default App;
