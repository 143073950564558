import React from 'react'
import './Homecomp3.css'
import { Link } from 'react-router-dom'; 
const Homecomp3 = () => {
  return (
    <div className='homecomp3-main'>
        <div className='homecomp3-feature'>
        <h2>Featured Properties</h2>
        <p>Exclusive real estate properties from renowned developers!</p>
        </div>
        <div className='homecomp3-btn'>
        <Link to='/viewall'>View All</Link>
        </div>

    </div>
  )
}

export default Homecomp3