import React from 'react'
import Blogcomp from '../components/Blogcomp'
const Blog = () => {
  return (
    <div>
        <Blogcomp/>
    </div>
  )
}

export default Blog