import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import './Nav2.css';
import Logo from '../images/prop2logo.png';

const Nav2 = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const isActive = (path) => location.pathname === path;

  return (
    <nav className='nav-container'>
      <div className='image-container'>
        <img src={Logo} alt='Logo' />
      </div>
      <div className='burger' onClick={toggleMenu}>
        <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} size="2x" color="darkblue" />
      </div>
      <div className={`menu ${isMenuOpen ? 'open' : ''}`}>
        <ul className='list-container'>
          <li>
            <Link to='/' className={isActive('/') ? 'selected' : ''} onClick={toggleMenu}>Home</Link>
          </li>
          <li>
            <Link to='/about' className={isActive('/about') ? 'selected' : ''} onClick={toggleMenu}>About Us</Link>
          </li>
          <li>
            <Link to='/contact' className={isActive('/contact') ? 'selected' : ''} onClick={toggleMenu}>Contact Us</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Nav2;
