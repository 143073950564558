import React from 'react'
import './Blogcomp.css'
import { Link } from 'react-router-dom'; 
import blog1 from '../images/blogcard.jpeg'
import blog2 from '../images/blogcard2.jpg'

const Blogcomp = () => {
  return (
    
    <div className='blogcomp-main'>
        
        <h1 className='blogcomp-heading'>Blog Heading</h1>
        <br></br>
        <div className='blogcomp-card-container'>
            <div className='blogcomp-card'>
                <div className='blogcomp-image'>
                  <img src={blog1} alt=''></img>
                </div>
                <h5 className='blogcomp-card-heading'>Pune Real Estate Market Forecast & Trends 2024: Exploring Future Opportunities and insights</h5>
                <p className='blogcomp-card-sub'>February 09, 2024</p>
                <br></br>
                <Link to='/blog'>READ MORE</Link>
            </div>
            <div className='blogcomp-card'>
            <div className='blogcomp-image'>
                  <img src={blog2} alt=''></img>
                </div>
                <h5 className='blogcomp-card-heading'>Construction Technology in India: Building the Future</h5>
                <p className='blogcomp-card-sub'>March 04, 2024</p>
                <br></br>
                <Link to='/blog'>READ MORE</Link>
            </div>
        </div>
       
    </div>
  )
}

export default Blogcomp