import React from 'react'
import About1component from '../components/About1component'
 import About2component from '../components/About2component'
import About3component from '../components/About3component'
import About4component from '../components/About4component'
import About5component from '../components/About5component'
const About = () => {
  return (
    <div>
        <About1component/>
   <About2component/>
     <About3component/>
       <About4component/> 
        <About5component/> 
    </div>
  )
}

export default About