import React from 'react'
import { Link } from 'react-router-dom';
import banner1 from '../images/Career1_enhanced.png'
import './Careercomp1.css'
const Careercomp1 = () => {
  return (
    <div className='careercomp1-main'>
      <div className='careercomp1-info'>
      <p className='comp1-head-p'>CAREERS AT PROP ASSIST</p>
      <h1>Work with us</h1>
      <p className='comp1-bottom-1'>We are seeking talented professionals who embody our core values of integrity, commitment, and excellence.</p>
      <button> <Link to='/'> View Current Openings</Link></button>
      </div>
      <div className='carrercomp1-banner'>
        <img src={banner1} alt=''></img>
      </div>
    </div>
  )
}

export default Careercomp1