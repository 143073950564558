import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import image1 from '../images/1o.png';
import image2 from '../images/2o.jpg';
import image3 from '../images/3o.png';
import image4 from '../images/4o.png';
import image5 from '../images/5o.jpg';
import image6 from '../images/6o.png';
import image7 from '../images/7o.jpg';
import image8 from '../images/9o.jpg';
import './Homecomponent8.css';
import "slick-carousel/slick/slick.css"; // Import slick styles
import "slick-carousel/slick/slick-theme.css"; // Import slick theme styles

function Homecomponent8() {
  const [slidesToShow, setSlidesToShow] = useState(5); // Default to 5 slides

  // Function to update slidesToShow based on window width
  const updateSlidesToShow = () => {
    if (window.innerWidth < 768) {
      setSlidesToShow(3); // Show 2 slides on mobile
    } else {
      setSlidesToShow(5); // Show 5 slides on larger screens
    }
  };

  useEffect(() => {
    // Set initial slides based on the current window width
    updateSlidesToShow();

    // Add event listener for window resize
    window.addEventListener("resize", updateSlidesToShow);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateSlidesToShow);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <div className="slider-container-3">
      <Slider {...settings}>
        <div className="homecomponent8-main">
          <div className="home8-img-cont">
            <img src={image1} alt="" />
          </div>
        </div>
        <div className="homecomponent8-main">
          <div className="home8-img-cont">
            <img src={image2} alt="" />
          </div>
        </div>
        <div className="homecomponent8-main">
          <div className="home8-img-cont">
            <img src={image3} alt="" />
          </div>
        </div>
        <div className="homecomponent8-main">
          <div className="home8-img-cont">
            <img src={image4} alt="" />
          </div>
        </div>
        <div className="homecomponent8-main">
          <div className="home8-img-cont">
            <img src={image5} alt="" />
          </div>
        </div>
        <div className="homecomponent8-main">
          <div className="home8-img-cont">
            <img src={image6} alt="" />
          </div>
        </div>
        <div className="homecomponent8-main">
          <div className="home8-img-cont">
            <img src={image7} alt="" />
          </div>
        </div>
        <div className="homecomponent8-main">
          <div className="home8-img-cont">
            <img src={image8} alt="" />
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default Homecomponent8;
