import React from 'react'
import service1 from '../images/prop-service,1_enhanced.png'
import service2 from '../images/prop-service,2_enhanced.png'
import service3 from '../images/prop-service,3_enhanced.png'
import service4 from '../images/prop-service,4_enhanced.png'
import './Homecomp1.css'
const Homecomp1 = () => {
  return (
    <div className='homecomp1-main'>
    <h1>Our Services</h1>
    <div className='homecomp1-cont'>
      <div className='homecomp1-card'>
        <div className='homecomp1-card-image'>
          <img src={service1} alt=''></img>
        </div>
        <h2>Premium Residential</h2>
        <p>Discover exquisite homes tailored to your luxury living aspirations!</p>
      </div>
      <div className='homecomp1-card'>
        <div className='homecomp1-card-image'>
          <img src={service2} alt=''></img>
        </div>
        <h2>Commercial Spaces</h2>
        <p>Explore tailored solutions for your business space needs with us.</p>
      </div>
      <div className='homecomp1-card'>
        <div className='homecomp1-card-image'>
          <img src={service3} alt=''></img>
        </div>
        <h2>Pre-Leased Assets</h2>
        <p>Invest effortlessly with our pre-leased assets for consistent returns.</p>
      </div>
      <div className='homecomp1-card'>
        <div className='homecomp1-card-image'>
          <img src={service4} alt=''></img>
        </div>
        <h2>Corporate Leasing</h2>
        <p>Secure the ideal corporate space for your business with our leasing expertise.</p>
      </div>
    </div>
  </div>
  )
}

export default Homecomp1